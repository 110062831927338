import React from 'react';
import { useDrop } from 'react-dnd';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';

import { Section } from '../types/section';
import { Type } from '../types/type';

interface IProps {
  index: number;
  data: Section;
  list: string[];
  showErrors: boolean;
  reset: (index: number) => void;
}

export const Block = ({ index, data, list, showErrors, reset }: IProps): JSX.Element => {
  const [{ canDrop, isOver }, dropRef] = useDrop(
    () => ({
      accept: Type.BLOCK,
      drop: () => ({
        index
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop() && typeof data.index !== 'number'
      })
    }),
    [data]
  );

  const value: string | null = typeof data.index === 'number' ? list[data.index] : null;
  const isActive = canDrop && isOver;

  return (
    <>
      <div
        ref={dropRef}
        className={clsx('block', {
          'block--can-drop': canDrop,
          'block--active': isActive,
          'block--empty': !value,
          'block--with-error': showErrors && value !== data.correctValue,
        })}
        style={{
          top: data.top,
          left: data.left,
          width: data.width,
          height: data.height
        }}
        data-for={`block-${index}`}
        data-tip={data.correctValue}
        data-tip-disable={!showErrors || value === data.correctValue}
      >
        {value}
        {
          value && !showErrors ? (
            <i
              className='block__reset-btn icon-cross'
              onClick={() => reset(index)}
            />
          ) : null
        }
      </div>
      <ReactTooltip id={`block-${index}`} place="bottom" type="error" />
    </>
  );
};
