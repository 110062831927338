import { Section } from '../types/section';

const links: Section[] = [
  {
    top: '0.5vw',
    left: '15.5vw',
    width: '10vw',
    height: '5vw',
    index: null,
    correctValue: 'Масляные дистилляры с ОНПЗ'
  },
  {
    top: '0.5vw',
    left: '42vw',
    width: '7vw',
    height: '5vw',
    index: null,
    correctValue: 'Гудрон'
  },
  {
    top: '5.6vw',
    left: '2vw',
    width: '7.8vw',
    height: '5vw',
    index: null,
    correctValue: 'Экстракты нефтяные'
  },
  {
    top: '5.6vw',
    left: '30.5vw',
    width: '7vw',
    height: '5vw',
    index: null,
    correctValue: 'Деасфальтизат'
  },
  {
    top: '6.6vw',
    left: '53.5vw',
    width: '7vw',
    height: '3vw',
    index: null,
    correctValue: 'Асфальт'
  },
  {
    top: '9.7vw',
    left: '53.5vw',
    width: '7vw',
    height: '4vw',
    index: null,
    correctValue: 'Асфальт'
  },
  {
    top: '10.5vw',
    left: '25.5vw',
    width: '7vw',
    height: '5vw',
    index: null,
    correctValue: 'Экстракты нефтяные'
  },
  {
    top: '10.5vw',
    left: '10.5vw',
    width: '7vw',
    height: '5vw',
    index: null,
    correctValue: 'Рафинаты'
  },
  {
    top: '14.5vw',
    left: '2.5vw',
    width: '7vw',
    height: '5vw',
    index: null,
    correctValue: 'Гач, петролатум'
  },
  {
    top: '5.5vw',
    left: '62.5vw',
    width: '7vw',
    height: '5vw',
    index: null,
    correctValue: 'Топливный газ'
  },
  {
    top: '5.5vw',
    left: '69.5vw',
    width: '7vw',
    height: '5vw',
    index: null,
    correctValue: 'Воздух'
  },
  {
    top: '14.5vw',
    left: '30.5vw',
    width: '7vw',
    height: '4vw',
    index: null,
    correctValue: 'Гач, петролатум'
  },
  {
    top: '19.5vw',
    left: '26.5vw',
    width: '16vw',
    height: '3vw',
    index: null,
    correctValue: 'Парафиново-восковая продукция / Экстракты'
  },
  {
    top: '19.2vw',
    left: '15.5vw',
    width: '7vw',
    height: '4vw',
    index: null,
    correctValue: 'Компоненты масел'
  },
  {
    top: '17.6vw',
    left: '62.5vw',
    width: '7vw',
    height: '5vw',
    index: null,
    correctValue: 'Интертный газ'
  },
  {
    top: '17.6vw',
    left: '69.5vw',
    width: '7vw',
    height: '5vw',
    index: null,
    correctValue: 'СО²'
  },
  {
    top: '23vw',
    left: '3vw',
    width: '7vw',
    height: '4vw',
    index: null,
    correctValue: 'Товарные масла'
  },
  {
    top: '22.5vw',
    left: '30vw',
    width: '7vw',
    height: '3vw',
    index: null,
    correctValue: 'Компоненты масел'
  },
  {
    top: '25.5vw',
    left: '30vw',
    width: '7vw',
    height: '3vw',
    index: null,
    correctValue: 'Присадки'
  },
  {
    top: '22vw',
    left: '54.5vw',
    width: '7vw',
    height: '3vw',
    index: null,
    correctValue: 'Компоненты масел'
  },
  {
    top: '25vw',
    left: '54.5vw',
    width: '7vw',
    height: '3vw',
    index: null,
    correctValue: 'Присадки'
  },
  {
    top: '22vw',
    left: '76vw',
    width: '7vw',
    height: '3vw',
    index: null,
    correctValue: 'ГОК'
  },
  {
    top: '25vw',
    left: '76vw',
    width: '7vw',
    height: '3vw',
    index: null,
    correctValue: 'Сера'
  },
  {
    top: '28.5vw',
    left: '47.5vw',
    width: '7vw',
    height: '3vw',
    index: null,
    correctValue: 'Компоненты масел'
  },
  {
    top: '28.5vw',
    left: '31vw',
    width: '7vw',
    height: '3vw',
    index: null,
    correctValue: 'Экстракты нефтяные, асфальт, присадки'
  },
  {
    top: '28vw',
    left: '15.5vw',
    width: '7vw',
    height: '4vw',
    index: null,
    correctValue: 'Товарные масла'
  },
  {
    top: '37.75vw',
    left: '35vw',
    width: '7vw',
    height: '4vw',
    index: null,
    correctValue: 'Фасованная продукция'
  }
];

export default links;
