import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Timer from 'react-compound-timer';
import ReactModal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import './App.css';

// Components
import { Welcome } from './components/Welcome';
import { Block } from './components/Block';
import { Link } from './components/Link';
import { MenuItem } from './components/MenuItem';

// Data
import blocks from './data/blocks';
import links from './data/links';
import lines from './data/lines';

// Services
import { saveResults } from './services/airtable';

// Types
import { Section } from './types/section';
import { Type } from './types/type';

ReactModal.setAppElement('body');

const shuffle = (array: any[]): void => {
  array.sort(() => Math.random() - 0.5);
};

const sourceBlocks: string[] = blocks.map((block) => block.correctValue);
shuffle(sourceBlocks);

const sourceLinks: string[] = links.map((link) => link.correctValue);
shuffle(sourceLinks);

const initialTime = localStorage.startedAt ? Math.max(0, dayjs(localStorage.startedAt).add(20, 'minutes').diff(new Date(), 'milliseconds')) : null;
const initialBlocks = blocks.slice(0);
const initialLinks = links.slice(0);

const resetResultsInLocalStorage = (): void => {
  blocks.forEach((b, index) => {
    delete localStorage[`block${index}`];
  });

  links.forEach((l, index) => {
    delete localStorage[`link${index}`];
  });

  delete localStorage.startedAt;
  delete localStorage.name;
};

if (initialTime) {
  const lastBlockIndexForValue: { [value: string]: number } = {};
  initialBlocks.forEach((block, index) => {
    const blockValue = localStorage[`block${index}`];

    if (blockValue?.length) {
      const currentIndex = sourceBlocks.indexOf(
        blockValue,
        lastBlockIndexForValue[blockValue] ? lastBlockIndexForValue[blockValue] + 1 : 0
      );
      lastLinkIndexForValue[blockValue] = currentIndex;

      block.index = currentIndex;
    }
  });

  const lastLinkIndexForValue: { [value: string]: number } = {};
  initialLinks.forEach((link, index) => {
    const linkValue = localStorage[`link${index}`];

    if (linkValue?.length) {
      const currentIndex = sourceLinks.indexOf(
        linkValue,
        lastLinkIndexForValue[linkValue] ? lastLinkIndexForValue[linkValue] + 1 : 0
      );
      lastLinkIndexForValue[linkValue] = currentIndex;

      link.index = currentIndex;
    }
  });
} else {
  resetResultsInLocalStorage();
}

const App = (): JSX.Element => {
  const timerRef = useRef<Timer>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [resultBlocks, setResultBlocks] = useState<Section[]>(initialBlocks);
  const [resultLinks, setResultLinks] = useState<Section[]>(initialLinks);
  const [started, setStarted] = useState<boolean>(!!initialTime);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [points, setPoints] = useState<number>(0);
  const [name, setName] = useState<string>(localStorage.name || '');

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const resetBlock = (index: number): void => {
    if (typeof resultBlocks[index].index !== 'number') {
      return;
    }

    resultBlocks[index].index = null;
    setResultBlocks(resultBlocks.slice());
    delete localStorage[`block${index}`];
  };

  const resetLink = (index: number): void => {
    if (typeof resultLinks[index].index !== 'number') {
      return;
    }

    resultLinks[index].index = null;
    setResultLinks(resultLinks.slice());
    delete localStorage[`link${index}`];
  };

  const moveBlock = (dropIndex: number, itemIndex: number): void => {
    if (typeof resultBlocks[dropIndex].index === 'number') {
      return;
    }

    resultBlocks[dropIndex].index = itemIndex;
    setResultBlocks(resultBlocks.slice());
    localStorage[`block${dropIndex}`] = sourceBlocks[itemIndex];
  };

  const moveLink = (dropIndex: number, itemIndex: number): void => {
    if (typeof resultLinks[dropIndex].index === 'number') {
      return;
    }

    resultLinks[dropIndex].index = itemIndex;
    setResultLinks(resultLinks.slice());
    localStorage[`link${dropIndex}`] = sourceLinks[itemIndex];
  };

  const showDialogWithResult = (): void => {
    const blockPoints = resultBlocks.reduce((prev, item: Section) => (
      typeof item.index === 'number' && sourceBlocks[item.index] === item.correctValue
        ? prev + 3
        : prev
    ), 0);
    const linkPoints = resultLinks.reduce((prev, item: Section) => (
      typeof item.index === 'number' && sourceLinks[item.index] === item.correctValue
        ? prev + (item.correctValue === 'Экстракты нефтяные, асфальт, присадки' ? 3 : 2)
        : prev
    ), 0);

    setPoints(blockPoints + linkPoints);
    saveResults(name, sourceBlocks, sourceLinks, resultBlocks, resultLinks, blockPoints + linkPoints);
    setModalIsOpen(true);

    resetResultsInLocalStorage();
  };

  const startTimer = (): void => {
    setStarted(true);
    localStorage.startedAt = new Date().toISOString();
    localStorage.name = name;
  }

  const check = (): void => {
    if (!showErrors) {
      setShowErrors(true);
      // @ts-ignore
      timerRef.current.stop();
      showDialogWithResult();
    }
  };

  const isCheckBtnDisabled =
    resultBlocks.some((block) => typeof block.index !== 'number') ||
    resultLinks.some((link) => typeof link.index !== 'number');

  return (
    <>
      {!started && !showErrors ? (
        <Welcome />
      ) : null}
      <div
        className={clsx(
          'app',
          {
            'app--not-started': !started
          }
        )}
      >
        <div className="map">
          <p
            className="map-label"
            style={{
              top: '3.5vw',
              left: '0.5vw'
            }}
          >
            На ОНПЗ
          </p>
          <p
            className="map-label"
            style={{
              top: '3.5vw',
              left: '59.4vw'
            }}
          >
            На ОНПЗ
          </p>
          {lines.map((line, index) => (
            <div
              key={index}
              className={clsx(
                'line',
                `line--${line.direction}`,
                {
                  'line--with-border': line.withBorder,
                  'line--with-top-arrow': line.direction === 'vertical' ? line.topArrow : false,
                  'line--with-bottom-arrow': line.direction === 'vertical' ? line.bottomArrow : false,
                  'line--with-left-arrow': line.direction === 'horizontal' ? line.leftArrow : false,
                  'line--with-right-arrow': line.direction === 'horizontal' ? line.rightArrow : false
                }
              )}
              style={{
                top: `${Math.round(Number(line.top) / 1440 * 10000) / 100}vw`,
                left: `${Math.round(Number(line.left) / 1440 * 10000) / 100}vw`,
                width: line.direction === 'horizontal' ? `${Math.round(Number(line.width) / 1440 * 10000) / 100}vw` : undefined,
                height: line.direction === 'vertical' ? `${Math.round(Number(line.height) / 1440 * 10000) / 100}vw` : undefined
              }}
            />
          ))}
          {
            resultBlocks.map((block, index) => (
              <Block
                key={index}
                index={index}
                data={block}
                list={sourceBlocks}
                showErrors={showErrors}
                reset={resetBlock}
              />
            ))
          }
          {
            resultLinks.map((link, index) => (
              <Link
                key={index}
                index={index}
                data={link}
                list={sourceLinks}
                showErrors={showErrors}
                reset={resetLink}
              />
            ))
          }
        </div>
        <div className="sidebar">
          <div className="check-btn__wrapper">
            <Timer
              ref={timerRef}
              initialTime={initialTime || 20 * 60 * 1000}
              startImmediately={!!initialTime}
              direction="backward"
              checkpoints={[
                {
                  time: 0,
                  callback: check
                }
              ]}
            >
              {/* @ts-ignore */}
              {({ start }) => (
                <>
                  <div className="timer">
                    <Timer.Minutes />:<Timer.Seconds
                    formatValue={(value) => value.toString().length > 1 ? value.toString() : `0${value}`} />
                  </div>
                  {!started ? (
                    <>
                      <button
                        className="check-btn"
                        onClick={() => {
                          startTimer();
                          start();
                        }}
                        disabled={name.length < 5}
                      >
                        Начать
                      </button>
                      <div className="form-name">
                        <input
                          ref={inputRef}
                          className="form-name__field"
                          onChange={(event) => setName(event.target.value)}
                          value={name}
                          placeholder="Введите ФИО"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        data-for="main"
                        data-tip="Сначала заполните все ячейки"
                        data-tip-disable={!isCheckBtnDisabled}
                        data-iscapture="true"
                      >
                        <button
                          className="check-btn"
                          onClick={check}
                          disabled={isCheckBtnDisabled || showErrors}
                        >
                          Проверить
                        </button>
                      </div>
                      <ReactTooltip id="main" place="bottom" type="info" />
                    </>
                  )}
                </>
              )}
            </Timer>
          </div>
          <p className="section">Блоки</p>
          <ul className="menu">
            {
              sourceBlocks.map((block, index) => (
                <MenuItem
                  key={index}
                  type={Type.BLOCK}
                  index={index}
                  move={moveBlock}
                  disabled={resultBlocks.some((cBlock) => cBlock.index === index) || showErrors}
                >
                  {block}
                </MenuItem>
              ))
            }
          </ul>
          <p className="section">Связи</p>
          <ul className="menu">
            {
              sourceLinks.map((link, index) => (
                <MenuItem
                  key={index}
                  type={Type.LINK}
                  index={index}
                  move={moveLink}
                  disabled={resultLinks.some((cLink) => cLink.index === index) || showErrors}
                >
                  {link}
                </MenuItem>
              ))
            }
          </ul>
        </div>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        className="result-modal"
        contentLabel="Результаты"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div className="result-modal__content">
          <h3>Результаты</h3>
          <p>Набрано очков: {points}/100</p>
          {
            points < 100 ? (
              <button
                className="result-modal__close-btn"
                onClick={() => setModalIsOpen(false)}
              >
                Посмотреть ошибки
              </button>
            ) : null
          }
        </div>
      </ReactModal>
    </>
  );
};

export default App;
