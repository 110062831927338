interface VerticalLine {
  direction: 'vertical';
  top: number;
  left: number;
  height: number;
  withBorder?: boolean;
  topArrow?: boolean;
  bottomArrow?: boolean;
}

interface HorizontalLine {
  direction: 'horizontal';
  top: number;
  left: number;
  width: number;
  withBorder?: boolean;
  leftArrow?: boolean;
  rightArrow?: boolean;
}

const lines: (VerticalLine | HorizontalLine)[] = [
  {
    direction: 'vertical',
    left: 30,
    top: 70,
    height: 180,
    topArrow: true,
  },
  {
    direction: 'vertical',
    left: 296,
    top: 42,
    height: 38,
    bottomArrow: true,
  },
  {
    direction: 'vertical',
    left: 246,
    top: 152,
    height: 62,
    bottomArrow: true,
  },
  {
    direction: 'vertical',
    left: 656,
    top: 42,
    height: 38,
    bottomArrow: true,
  },
  {
    direction: 'horizontal',
    left: 30,
    top: 120,
    width: 120
  },
  {
    direction: 'horizontal',
    left: 433,
    top: 120,
    width: 120,
    leftArrow: true,
  },
  {
    direction: 'horizontal',
    left: 750,
    top: 120,
    width: 130
  },
  {
    direction: 'horizontal',
    left: 750,
    top: 140,
    width: 130
  },
  {
    direction: 'vertical',
    left: 880,
    top: 70,
    height: 50,
    topArrow: true,
  },
  {
    direction: 'horizontal',
    left: 480,
    top: 170,
    width: 400
  },
  {
    direction: 'vertical',
    left: 480,
    top: 170,
    height: 40
  },
  {
    direction: 'horizontal',
    left: 480,
    top: 210,
    width: 67,
    rightArrow: true,
  },
  {
    direction: 'vertical',
    left: 880,
    top: 140,
    height: 150
  },
  {
    direction: 'horizontal',
    left: 30,
    top: 250,
    width: 120
  },
  {
    direction: 'vertical',
    left: 346,
    top: 152,
    height: 38
  },
  {
    direction: 'horizontal',
    left: 346,
    top: 190,
    width: 134
  },
  {
    direction: 'vertical',
    left: 656,
    top: 290,
    height: 35,
    bottomArrow: true,
  },
  {
    direction: 'horizontal',
    left: 656,
    top: 290,
    width: 224
  },
  {
    direction: 'vertical',
    left: 626,
    top: 250,
    height: 50
  },
  {
    direction: 'horizontal',
    left: 50,
    top: 300,
    width: 576
  },
  {
    direction: 'vertical',
    left: 296,
    top: 285,
    height: 40,
    withBorder: true,
    bottomArrow: true,
  },
  {
    direction: 'horizontal',
    left: 420,
    top: 240,
    width: 125,
    rightArrow: true,
  },
  {
    direction: 'vertical',
    left: 951,
    top: 115,
    height: 67,
    bottomArrow: true,
  },
  {
    direction: 'vertical',
    left: 1052,
    top: 115,
    height: 67,
    bottomArrow: true,
  },
  {
    direction: 'vertical',
    left: 962,
    top: 245,
    height: 80,
    bottomArrow: true,
  },
  {
    direction: 'vertical',
    left: 1063,
    top: 245,
    height: 80,
    bottomArrow: true,
  },
  {
    direction: 'horizontal',
    left: 1093,
    top: 340,
    width: 100,
    leftArrow: true,
  },
  {
    direction: 'horizontal',
    left: 1093,
    top: 383,
    width: 100,
    leftArrow: true,
  },
  {
    direction: 'horizontal',
    left: 763,
    top: 340,
    width: 144,
    rightArrow: true,
  },
  {
    direction: 'horizontal',
    left: 763,
    top: 383,
    width: 144,
    leftArrow: true,
  },
  {
    direction: 'horizontal',
    left: 433,
    top: 347,
    width: 114,
    rightArrow: true,
  },
  {
    direction: 'horizontal',
    left: 433,
    top: 391,
    width: 114,
    leftArrow: true,
  },
  {
    direction: 'horizontal',
    left: 453,
    top: 573,
    width: 204
  },
  {
    direction: 'vertical',
    left: 453,
    top: 540,
    height: 33
  },
  {
    direction: 'vertical',
    left: 657,
    top: 540,
    height: 33
  },
  {
    direction: 'vertical',
    left: 555,
    top: 573,
    height: 32,
    bottomArrow: true,
  },
  {
    direction: 'horizontal',
    left: 778,
    top: 505,
    width: 72,
    leftArrow: true,
  },
  {
    direction: 'horizontal',
    left: 588,
    top: 640,
    width: 62,
    rightArrow: true,
  },
  {
    direction: 'vertical',
    left: 655,
    top: 395,
    height: 40,
  },
  {
    direction: 'horizontal',
    left: 655,
    top: 435,
    width: 275,
  },
  {
    direction: 'vertical',
    left: 930,
    top: 435,
    height: 35,
    bottomArrow: true,
  },
  {
    direction: 'vertical',
    left: 625,
    top: 395,
    height: 30,
  },
  {
    direction: 'horizontal',
    left: 330,
    top: 425,
    width: 295,
  },
  {
    direction: 'vertical',
    left: 330,
    top: 425,
    height: 145,
  },
  {
    direction: 'horizontal',
    left: 250,
    top: 570,
    width: 80,
  },
  {
    direction: 'vertical',
    left: 250,
    top: 570,
    height: 35,
    bottomArrow: true,
  },
  {
    direction: 'vertical',
    left: 210,
    top: 540,
    height: 65,
    bottomArrow: true,
  },
  {
    direction: 'vertical',
    left: 180,
    top: 560,
    height: 45,
    bottomArrow: true,
  },
  {
    direction: 'horizontal',
    left: 80,
    top: 560,
    width: 102,
  },
  {
    direction: 'vertical',
    left: 80,
    top: 362,
    height: 198,
  },
  {
    direction: 'horizontal',
    left: 80,
    top: 362,
    width: 65,
  },
  {
    direction: 'vertical',
    left: 150,
    top: 575,
    height: 30,
    bottomArrow: true,
  },
  {
    direction: 'vertical',
    left: 50,
    top: 300,
    height: 275,
  },
  {
    direction: 'horizontal',
    left: 50,
    top: 575,
    width: 102,
  },
  {
    direction: 'vertical',
    left: 210,
    top: 440,
    height: 30,
    bottomArrow: true,
  },
  {
    direction: 'horizontal',
    left: 211,
    top: 440,
    width: 250,
    withBorder: true,
  },
  {
    direction: 'vertical',
    left: 460,
    top: 440,
    height: 30,
    bottomArrow: true,
  },
  {
    direction: 'vertical',
    left: 300,
    top: 395,
    height: 45,
  },
];

export default lines;
