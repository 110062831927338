import Airtable from 'airtable';
import { Records } from 'airtable/lib/records';

// Types
import { Section } from '../types/section';

Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: 'keypHSDu5yY0QLm8b',
});
const airtableBase = Airtable.base('appZ9GufUhloNJWJM');

export const saveResults = async (name: string, sourceBlocks: string[], sourceLinks: string[], resultBlocks: Section[], resultLinks: Section[], result: number): Promise<Records<any>> => {
  const fields: { [key: string]: string | number } = {
    Created: new Date().toISOString(),
    Name: name,
    Result: result / 100,
  };

  resultBlocks.forEach((block, index) => {
    fields[`Block ${index + 1}`] = typeof block.index === 'number' ? sourceBlocks[block.index] : '-';
  });

  resultLinks.forEach((link, index) => {
    fields[`Link ${index + 1}`] = typeof link.index === 'number' ? sourceLinks[link.index] : '-';
  });

  return airtableBase('Результаты').create([{ fields }]);
};
