import React from 'react';

export const Welcome = (): JSX.Element => {
  return (
    <div className="welcome">
      <div className="welcome__wrapper">
        <img src='./logo.jpg' alt="Лучший по профессии 2021" className="welcome__logo" />
        <p style={{ textAlign: 'center' }}>Уважаемые коллеги!</p>
        <p>Вам необходимо воссоздать <b>принципиальную схему Омского завода смазочных материалов</b>, используя соответствующие элементы схемы (блоки,  потоки),  находящиеся справа.</p>
        <p>Для этого необходимо «перетащить» соответствующие элементы в выбранное Вами поле на схеме.</p>
        <br />
        <ul>
          <li>Время выполнения задания:  20 минут. </li>
          <li>Максимальное количество  баллов: 100.</li>
          <li>По завершению 20 минут задание автоматически завершится и выведется итоговая оценка.</li>
        </ul>
        <br />
        <p>Если задание выполнено раньше времени, то можно нажать кнопку <b>«Проверить»</b> (активируется,  когда все поля будут заполнены).</p>
        <p>Если Вам понятна инструкция, и Вы готовы к прохождению задания, введите ваши ФИО справа и нажмите кнопку <b>«Начать»</b>.</p>
      </div>
    </div>
  );
};
