import { Section } from '../types/section';

const blocks: Section[] = [
  {
    top: '5.6vw',
    left: '10vw',
    width: '20vw',
    height: '5vw',
    index: null,
    correctValue: 'Селективная очистка масел, 37/1 №4 (фенол), 37/1 №5 (NMP)'
  },
  {
    top: '5.6vw',
    left: '38vw',
    width: '15vw',
    height: '5vw',
    index: null,
    correctValue: 'Деасфальтизация масел, 36/1 №1, 36/1 №3, 36/3 №4'
  },
  {
    top: '14.8vw',
    left: '10vw',
    width: '20vw',
    height: '5vw',
    index: null,
    correctValue: 'Депарафинизация масел, 39/1 №2, 39/6 №4, 39/8 №5'
  },
  {
    top: '12.6vw',
    left: '38vw',
    width: '15vw',
    height: '5vw',
    index: null,
    correctValue: 'РП-4'
  },
  {
    top: '12.6vw',
    left: '63vw',
    width: '13vw',
    height: '5vw',
    index: null,
    correctValue: 'Производство инертного газа'
  },
  {
    top: '22.6vw',
    left: '10vw',
    width: '20vw',
    height: '5vw',
    index: null,
    correctValue: 'Производство товарных масел, РП-1, РП-3, УСМ'
  },
  {
    top: '22.6vw',
    left: '38vw',
    width: '15vw',
    height: '5vw',
    index: null,
    correctValue: 'ТСУ-2'
  },
  {
    top: '22.6vw',
    left: '63vw',
    width: '13vw',
    height: '5vw',
    index: null,
    correctValue: 'Сульфонатные присадки'
  },
  {
    top: '32.6vw',
    left: '8vw',
    width: '13vw',
    height: '5vw',
    index: null,
    correctValue: 'Участок хранения, отгрузки масел Насосная титул 152'
  },
  {
    top: '32.6vw',
    left: '25vw',
    width: '13vw',
    height: '5vw',
    index: null,
    correctValue: 'Участок фасовки масел'
  },
  {
    top: '32.6vw',
    left: '39vw',
    width: '15vw',
    height: '5vw',
    index: null,
    correctValue: 'Участок затаривания литиевых смазок и изготовления тары'
  },
  {
    top: '32.6vw',
    left: '58vw',
    width: '13vw',
    height: '5vw',
    index: null,
    correctValue: 'Литиевые смазки'
  },
  {
    top: '42vw',
    left: '8vw',
    width: '13vw',
    height: '5vw',
    index: null,
    correctValue: 'Налив авто-, вагон-цистерн'
  },
  {
    top: '42vw',
    left: '28vw',
    width: '13vw',
    height: '5vw',
    index: null,
    correctValue: 'Склады управления логистики и транспорта'
  },
  {
    top: '42vw',
    left: '45vw',
    width: '13vw',
    height: '5vw',
    index: null,
    correctValue: 'Отгрузки автомашин, контейнеров, крытых вагонов'
  }
];

export default blocks;
