import React from 'react';
import { useDrag } from 'react-dnd';
import { Type } from '../types/type';

interface IProps {
  type: Type;
  index: number;
  children: string;
  disabled: boolean;
  move: (dropIndex: number, itemIndex: number) => void;
}

export const MenuItem = ({ type, index, move, disabled, children }: IProps): JSX.Element => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type,
      item: { index },
      canDrag: !disabled,
      collect: (monitor) => ({
        opacity: monitor.isDragging() || disabled ? 0.5 : 1
      }),
      end(item, monitor) {
        const dropResult = monitor.getDropResult() as { dropEffect: string, index: number };

        if (item && dropResult && dropResult.dropEffect === 'move') {
          move(dropResult.index, item.index);
        }
      }
    }),
    [disabled]
  );

  return (
    <li
      ref={dragRef}
      className="menu__item"
      style={{
        opacity
      }}
    >
      {children}
    </li>
  );
};
